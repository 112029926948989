<template>
  <div>
    <ejs-dialog
        ref="corporationRegistrationPopup"
        header="거래처정보 상세"
        :allowDragging="true"
        :showCloseIcon="true"
        width="832"
        :isModal="true"
        :close="onDialogClose"
    >
      <div class="window accountInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">거래처정보 상세</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">거래처 기본정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">거래처명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="bcncName"
                                                v-model="corporationInfo.bcncName"
                                                @blur="onBcncNameBlur"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      사업자 번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                                ref="bizNo"
                                                class="e-input"
                                                v-model="corporationInfo.bizNo"
                                                mask="000-00-00000"
                                                @blur="onBizNoBlur"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">대표자명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="rprsntvName"
                                                v-model="
                                                corporationInfo.rprsntvName
                                              "
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
<!--                                  <li class="field">-->
<!--                                    <div class="title">법인번호</div>-->
<!--                                    <div class="content">-->
<!--                                      <ul class="row">-->
<!--                                        <li class="item form-group">-->
<!--                                          <div class="form">-->
<!--                                            <ejs-maskedtextbox-->
<!--                                                ref="cprNo"-->
<!--                                                class="e-input"-->
<!--                                                v-model="corporationInfo.cprNo"-->
<!--                                                @blur="onCprNoBlur"-->
<!--                                                mask="000000-0000000"-->
<!--                                                @keydown.native="-->
<!--                                                preventEnterEvent-->
<!--                                              "-->
<!--                                                maxLength="50"-->
<!--                                            />-->
<!--                                          </div>-->
<!--                                        </li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </li>-->
                                  <li class="field">
                                    <div class="title required">
                                      거래처 구분
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="bcncDiv"
                                                v-model="corporationInfo.bcncDiv"
                                                :dataSource="
                                                searchOptions.bcncDiv
                                              "
                                                :fields="commonCodeField"
                                                :allowFiltering="false"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
<!--                                  <li class="field">-->
<!--                                    <div class="title">주민번호</div>-->
<!--                                    <div class="content">-->
<!--                                      <ul class="row">-->
<!--                                        <li class="item form-group">-->
<!--                                          <div class="form">-->
<!--                                            <ejs-maskedtextbox-->
<!--                                                id="regNum"-->
<!--                                                class="e-input"-->
<!--                                                type="text"-->
<!--                                                v-model="corporationInfo.resNo"-->
<!--                                                mask="000000-0000000"-->
<!--                                                @keydown.native="-->
<!--                                                preventEnterEvent-->
<!--                                              "-->
<!--                                            />-->
<!--                                          </div>-->
<!--                                        </li>-->
<!--                                      </ul>-->
<!--                                    </div>-->
<!--                                  </li>-->
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">거래처 부가정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">업태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="bizcnd"
                                                v-model="corporationInfo.bizcnd"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">업종</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="induty"
                                                v-model="corporationInfo.induty"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">주요생산품</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="mainProduct"
                                                v-model="
                                                corporationInfo.mainProduct
                                              "
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">사용여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="radio"
                                                    id="useFlagTrue"
                                                    :value="true"
                                                    v-model="
                                                    corporationInfo.useFlag
                                                  "
                                                    name="useFlag"
                                                />
                                                <i></i>
                                                <div class="label">사용</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                    type="radio"
                                                    id="useFlagFalse"
                                                    :value="false"
                                                    v-model="
                                                    corporationInfo.useFlag
                                                  "
                                                    name="useFlag"
                                                />
                                                <i></i>
                                                <div class="label">
                                                  미사용
                                                </div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">거래처 형태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="bcncStle"
                                                v-model="corporationInfo.bcncStle"
                                                :dataSource="
                                                searchOptions.bcncStle
                                              "
                                                :fields="commonCodeField"
                                                :allowFiltering="false"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">거래처 부서</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="bcncDept"
                                                v-model="corporationInfo.bcncDept"
                                                :dataSource="
                                                searchOptions.bcncDept
                                              "
                                                :fields="commonCodeField"
                                                :allowFiltering="false"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">담당자/연락처</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">담당자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="mngr"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                v-model="corporationInfo.mngr"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">이메일</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                id="email"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                v-model="corporationInfo.email"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">전화번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                                id="telNo"
                                                v-model="corporationInfo.telNo"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">팩스번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                                id="faxNo"
                                                v-model="corporationInfo.faxNo"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field address">
                                    <div class="title">주소</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group post">
                                          <div class="form">
                                            <input-text
                                                id="postNo"
                                                v-model="corporationInfo.postNo"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li>
                                              <erp-button
                                                  button-div="GET"
                                                  @click.native="
                                                  onPostCodeClick()
                                                "
                                              >
                                                우편번호
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="addr1"
                                                v-model="corporationInfo.addr1"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="addr2"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                v-model="corporationInfo.addr2"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                                id="remarks"
                                                @keydown.native="
                                                preventEnterEvent
                                              "
                                                v-model="corporationInfo.remarks"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onDialogClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daum-postcode-popup
        ref="daumPostcodePopup"
        v-if="isDaumPostcodePopupOpen"
        @popupClose="daumPostcodePopupClosed"
        @popupConfirmed="daumPostcodePopupConfirmed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import {
  addAccountInfo,
  checkDuplicate,
} from "@/api/accountRegistration";
import { validateFormRefs, ValidType } from "@/utils/formUtil";
import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "corporationRegistrationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    DaumPostcodePopup,
    ErpButton,
  },
  data() {
    return {
      commonCodeField: { text: "comName", value: "comCode" },
      isDaumPostcodePopupOpen: false,
      rules: {
        bcncName: {
          required: true,
        },
        bizNo: {
          required: true,
        },
        rprsntvName: {
          required: true,
        },
        bcncDiv: {
          required: true,
        },
      },
      searchOptions: {
        bcncDiv: commonCodesGetCommonCode("BCNC_DIV"),
        bcncStle: commonCodesGetCommonCode("BCNC_STLE"),
        bcncDept: commonCodesGetCommonCode("BCNC_DEPT"),
      },
      originAccountInfo: {},
      corporationInfo: {
        accountId: null,
        bcncId: null, // number | null
        bcncName: null, // string | null
        bizNo: null, // string | null
        rprsntvName: null, // string | null
        cprNo: null, // string | null
        bcncDiv: commonCodeGetComCodeDefaultValue("BCNC_DIV"), // string | null
        // resNo: null, // string | null
        bizcnd: null, // string | null
        induty: null, // string | null
        mainProduct: null, // string | null
        useFlag: true, // boolean | null
        bcncStle: commonCodeGetComCodeDefaultValue("BCNC_STLE"), // string | null
        bcncDept: commonCodeGetComCodeDefaultValue("BCNC_DEPT"), // string | null
        mngr: null, // string | null
        postNo: null, // string | null
        addr1: null, // string | null
        addr2: null, // string | null
        remarks: null, // string | null
        bankCode: null, // string | null
        acnutNo: null, // string | null
        email: null, // string | null
        telNo: null, // string | null
        faxNo: null, // string | null
      },
    };
  },
  computed: {
    isPopupOpened() {
      return this.isDaumPostcodePopupOpen;
    },
  },
  methods: {
    validateFormRefs,
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    hide() {
      this.$refs.corporationRegistrationPopup.hide();
    },
    onDialogClose(registSuccess) {
      this.$emit("registPopupClose", typeof registSuccess === 'boolean');
    },
    async onSaveButtonClicked() {
      if (!this.validateFormRefs(this.rules)) {
        return;
      }

      await addAccountInfo(this.corporationInfo);

      this.infoToast(this.$t("main.popupMessage.saved"));
      this.onDialogClose(true);

    },
    async onBcncNameBlur({ value }) {
      if (!value || value === this.originAccountInfo.bcncName) {
        return;
      }

      const { value: duplicated } = await checkDuplicate(
          this.corporationInfo.bcncId,
          { bcncName: value }
      );

      if (duplicated) {
        this.errorToast(this.$t("main.popupMessage.duplicatedRequiredData"));
        this.corporationInfo.bcncName = "";
      }
    },
    async onBizNoBlur({ value }) {
      if (!value || value === this.originAccountInfo.bizNo) {
        return;
      }

      const { value: duplicated } = await checkDuplicate(
          this.corporationInfo.bcncId,
          { bizNo: value }
      );

      if (duplicated) {
        this.errorToast(this.$t("main.popupMessage.duplicatedRequiredData"));
        this.corporationInfo.bizNo = "";
      }
    },
    async onCprNoBlur({ value }) {
      if (!value || value === this.originAccountInfo.cprNo) {
        return;
      }

      const { value: duplicated } = await checkDuplicate(
          this.corporationInfo.bcncId,
          { cprNo: value }
      );

      if (duplicated) {
        this.errorToast(this.$t("main.popupMessage.duplicatedRequiredData"));
        this.corporationInfo.cprNo = "";
      }
    },

    onPostCodeClick() {
      this.onDaumPostcodePopupOpen();
    },
    onDaumPostcodePopupOpen() {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    daumPostcodePopupClosed() {
      this.isDaumPostcodePopupOpen = false;
    },
    daumPostcodePopupConfirmed(data) {
      this.corporationInfo.postNo = data.zonecode;
      this.corporationInfo.addr1 = data.address;
      this.daumPostcodePopupClosed();
    },
  },
};
</script>
