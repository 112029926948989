<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">회원명/회원번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="searchOptions.searchName"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">변경일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="changedDateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">상태</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    cssClass="body-data-dropdown"
                    v-model="searchOptions.status"
                    :dataSource="ejsSource.status"
                    :fields="ejsFields"
                ></ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">명의변경구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    cssClass="body-data-dropdown"
                    v-model="searchOptions.renameDiv"
                    :dataSource="ejsSource.renameDiv"
                    :fields="ejsFields"
                ></ejs-dropdownlist>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">명의변경 신청/확정</div>
              <div class="header-caption">[{{count}}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="changeApply"
                  >
                    변경신청
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="DELETE"
                      :is-icon-custom="true"
                      @click.native="onDeleteButtonClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="changeConfirmationClicked"
                  >
                    변경확정
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="DELETE"
                      :is-icon-custom="true"
                      @click.native="cancelConfirmationClicked"
                  >
                    확정취소
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="gridOptions"
                  :dataSource="gridDataSource"
                  @headerCellInfo="onHeaderCellInfo"
                  @queryCellInfo="onQueryCellInfo"
                  @actionComplete="onActionComplete"
                  @recordClick="onRecordClick"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <name-change-application-confirmation-popup
        ref="confirmationPopup"
        v-if="nameChangeApplicationConfirmationPopup"
        @close="popupClose"
        @search="onViewButtonClicked"
    >
    </name-change-application-confirmation-popup>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import InputText from "@/components/common/text/InputText.vue";
import NameChangeApplicationConfirmationPopup from "@/views/transfer-of-name/popup/NameChangeApplicationConfirmationPopup.vue";
import ErpButton from "@/components/button/ErpButton.vue";

import {ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import {numberWithCommas} from "@/utils/number";
import moment from "moment/moment";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default {
  name: "NameChangeApplicationConfirmation",
  components: {
    InputText,
    InputDateRange,
    ErpButton,
    EjsGridWrapper,
    NameChangeApplicationConfirmationPopup
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      gridDataSource: [],
      nameChangeApplicationConfirmationPopup: false,
      ejsFields: { text: "comName", value: "comCode" },
      ejsSource: {
        status: [
          {
            comName: "전체",
            comCode: ""
          },
          {
            comName: "확정",
            comCode: "true"
          },
          {
            comName: "미확정",
            comCode: "false"
          }
        ],
        renameDiv: [
          {
            comName: "전체",
            comCode: ""
          },
        ],
      },
      searchOptions: {
        searchName:"",
        changedDateFrom: null,
        changedDateTo: null,
        status:"",
        renameDiv: "",
      },
    };
  },
  async created() {
    const from = moment().startOf('month').format(DATE_FORMAT_YYYY_MM_DD);
    const to = moment().format(DATE_FORMAT_YYYY_MM_DD);
    this.changedDateRange = {
      from,
      to,
    };
    this.ejsSource.renameDiv.push(...commonCodesGetCommonCode("RENAME_DIV", true));
  },
  computed: {
    isPopupOpened() {
      return (
          this.nameChangeApplicationConfirmationPopup
      );
    },
    changedDateRange: {
      get() {
        return {
          from: this.searchOptions.changedDateFrom,
          to: this.searchOptions.changedDateTo,
        };
      },
      set(dateRange) {
        this.searchOptions.changedDateFrom = dateRange.from;
        this.searchOptions.changedDateTo = dateRange.to;
      }
    },
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          persistSelection: false,
          type: "Single",
          checkboxOnly: true,
          checkboxMode: "Default",
          enableToggle: false,
        },
        isAutoSelectRow: false,
        isAutoSelectCell: false,
        isSelectedRowRetain: false,
        // allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "flag",
            allowEditing: false,
            allowFiltering: false,
            type: "checkbox",
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: "renameDate",
            headerText: "변경일자",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "Center",
          },
          {
            field: "renameDiv",
            headerText: "명의변경구분",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "Center",
            groupCode: "RENAME_DIV",
            isCommonCodeField: true,
          },
          {
            field: "confirmFlag",
            headerText: "상태",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "Center",
            valueAccessor: (field, data) => {
              return data[field] ? "확정" : "미확정";
            }
          },
          {
            field: "saleNo",
            headerText: "입회번호",
            type: "number",
            minWidth: 16,
            width: 80,
            textAlign: "Center",
          },
          {
            headerText: "양도자",
            columns:[
              {
                field: "giveMemberName",
                headerText: "이름",
                type: "string",
                minWidth: 16,
                width: 100,
                textAlign: "Center",
              },
              {
                field: "giveMemberNo",
                headerText: "회원번호",
                type: "string",
                minWidth: 16,
                width: 130,
                textAlign: "Center",
                valueAccessor: (field, data) =>
                    gridUtilGetMemberNoColumnAccess(field, data),
              },
              {
                field: "giveCprDiv",
                headerText: "법인구분",
                type: "string",
                minWidth: 16,
                width: 120,
                textAlign: "Center",
                groupCode: "CPR_DIV",
                isCommonCodeField: true,
              },
            ]
          },
          {
            headerText: "양수자",
            columns:[
              {
                field: "takeMemberName",
                headerText: "이름",
                type: "string",
                minWidth: 16,
                width: 100,
                textAlign: "Center",
              },
              {
                field: "takeMemberNo",
                headerText: "회원번호",
                type: "string",
                minWidth: 16,
                width: 130,
                textAlign: "Center",
                valueAccessor: (field, data) =>
                    gridUtilGetMemberNoColumnAccess(field, data),
              },
              {
                field: "takeCprDiv",
                headerText: "법인구분",
                type: "string",
                minWidth: 16,
                width: 120,
                textAlign: "Center",
                groupCode: "CPR_DIV",
                isCommonCodeField: true,
              },
            ]
          },
          {
            field: "confirmName",
            headerText: "확정자명",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "Center",
          },
          {
            field: "confirmDt",
            headerText: "확정일시",
            type: "string",
            minWidth: 16,
            width: 280,
            textAlign: "Center",
          }
        ],
      };
    },
  },
  methods: {
    popupClose() {
      this.nameChangeApplicationConfirmationPopup = false;
    },
    changeApply(rowData) {
      const { renameDiv, renameRequestId, confirmFlag } = rowData;
      this.nameChangeApplicationConfirmationPopup = true;
      this.$nextTick(() => {
        this.$refs.confirmationPopup.show(renameDiv, renameRequestId, confirmFlag);
      });

    },
    onHeaderCellInfo(args) {
      if (args.cell.column.field === "renameDiv") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onQueryCellInfo(args) {
      const {
        column: { field },
        cell,
      } = args;
      if (field === "flag") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if(field === "renameDiv") {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;
      if(field === "renameDiv") {
        this.changeApply(rowData);
      }
    },
    onActionComplete() {
      this.count = numberWithCommas(
          this.$refs.grid?.getGridBatchCount() || 0
      );
    },
    async onViewButtonClicked() {
      const originData = await GolfErpAPI.getNameChangeApplicationConfirmation(this.searchOptions);
      this.gridDataSource = originData.map((item) => ({ flag: false, ...item }));
      this.$nextTick(() => {
        this.$refs.confirmationPopup.hide();
        this.nameChangeApplicationConfirmationPopup = false;
      });
    },
    async cancelConfirmationClicked() {
      try {
        const selectData = this.$refs.grid.getSelectedRecords();
        if (!selectData.length) {
          this.errorToast('데이터를 선택하여주시기 바랍니다');
          return;
        }
        const record = selectData.every((e) => e.confirmFlag);
        if (!record) {
          this.errorToast('상태가 확정인 DATA만 취소 가능합니다');
          return;
        }
        const cancelData = {
          renameRequestIds: selectData.map(item => item.renameRequestId),
        };
        await GolfErpAPI.cancelNameChangeApplicationConfirmation(cancelData);
        this.$refs.grid.clearSelection();
        await this.onViewButtonClicked();
      } catch (e) {
        this.errorToast('확정취소 중 에러가 발생하였습니다');
      }
    },

    async changeConfirmationClicked() {
      try {
        const selectData = this.$refs.grid.getSelectedRecords();
        if(!selectData.length) {
          this.errorToast('데이터를 선택하여주시기 바랍니다');
          return;
        }
        const record = selectData.every((e) => !e.confirmFlag );
        if(!record) {
          this.errorToast('상태가 미확정인 DATA만 확정 가능합니다');
          return;
        }
        if (!(await this.confirm("명의변경, 탈회건의 미래예약건은 확정 취소시에도 복구가 안됩니다."))) {
          return;
        }
        const confirmData = {
          renameRequestIds: selectData.map(item => item.renameRequestId),
        };
        await GolfErpAPI.confirmNameChangeApplicationConfirmation(confirmData);
        this.$refs.grid.clearSelection();
        await this.onViewButtonClicked();
      } catch (e) {
        this.errorToast('변경확정 중 에러가 발생하였습니다');
      }
    },
    async onDeleteButtonClicked() {
      try {
        const selectData = this.$refs.grid.getSelectedRecords();
        if (!selectData.length) {
          this.errorToast('데이터를 선택하여주시기 바랍니다');
          return;
        }
        const record = selectData.every((e) => !e.confirmFlag);
        if (!record) {
          this.errorToast('상태가 미확정인 DATA만 삭제 가능합니다');
          return;
        }
        const deleteData = {
          renameRequestIds: selectData.map(item => item.renameRequestId),
        };

        await GolfErpAPI.deleteNameChangeApplicationConfirmationDetail(deleteData);
        this.$refs.grid.clearSelection();
        await this.onViewButtonClicked();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>