<template>
  <div>
    <ejs-dialog
        ref="nameChangeApplicationPopup"
        header="명의변경신청"
        :allowDragging="true"
        :showCloseIcon="true"
        width="1000"
        height="auto"
        :enableResize="false"
        :isModal="true"
        @close="dialogClose"
        :cssClass="
        propsData
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
        "
    >
      <div class="window couponKindDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <ul class="lookup-condition">
                        <li class="field">
                          <div class="header-title">명의변경구분</div>
                        </li>
                      </ul>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <div class="title required">구분</div>
                          <ul class="content">
                            <li class="item">
                              <ejs-dropdownlist
                                  :allowFiltering="false"
                                  :fields="commonCodeFields"
                                  cssClass="body-data-dropdown"
                                  :dataSource="dropDownOption.renameDiv"
                                  v-model="renameDiv"
                                  :enabled="!propsData"
                                  @change="onChangeRenameDiv"
                              ></ejs-dropdownlist>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <li class="refresh">
                          <erp-button
                            button-div="GET"
                            :is-shortcut-button="true"
                            @click.native="refresh"
                          >
                            새로고침
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="section-body" class="section-body">
                    <article class="body-article">
                      <!-- 상세데이터 -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <ul class="lookup-condition">
                              <li class="field">
                                <div class="header-title">명의개서정보</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">
                                      입회번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ giver.saleNo || 'NA'}}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      신청일자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="requestDate"
                                                name="requestDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                v-model="detailsData.requestDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">명의변경일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="renameDate"
                                                name="renameDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                v-model="detailsData.renameDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field"/>
                                  <li class="field">
                                    <div class="title required">
                                      명의개서료
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                ref="takeRenameFee"
                                                name="takeRenameFee"
                                                v-model="detailsData.renameFee"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field" style="width: calc((100% / 4) * 3 + 1px)">
                                    <div class="title required" style="width: 130px">
                                      명의변경사유
                                    </div>
                                    <div class="content" style="width: 100%">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                                ref="takeRemarks"
                                                v-model="detailsData.remarks"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      확정여부
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ detailsData.confirmFlag ? '확정':'미확정' }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title" >
                                      확정자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ detailsData.confirmName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title" >
                                      확정일시
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ detailsData.confirmDt }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title"></div>
                                    <div class="content"></div>
                                  </li>
                                  <li class="field">
                                    <div
                                        class="title"
                                        :class="isNEW && 'required'"
                                        style="width: 124px">
                                      최초 분양일자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="frstIssueDate"
                                                name="frstIssueDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                :readonly="!isNEW"
                                                :disabled="!isNEW"
                                                v-model="detailsData.frstIssueDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                        class="title"
                                        :class="isNEW && 'required'"
                                        style="width: 124px">
                                      최초 분양금액
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                ref="frstIssueAmt"
                                                name="frstIssueAmt"
                                                :readonly="!isNEW"
                                                :disabled="!isNEW"
                                                v-model="detailsData.frstIssueAmt"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 양도자 -->
                      <section class="article-section section-0101" v-if="renameDiv !== 'NEW'">
                        <div class="section-header">
                          <div class="header-left">
                            <ul class="lookup-condition">
                              <li class="field">
                                <div class="header-title">양도자</div>
                              </li>
                              <li class="field" v-if="!propsData">
                                <div class="title">회원명/회원번호</div>
                                <ul class="content">
                                  <li class="item">
                                    <input-text
                                        v-model="searchData"
                                        @keydown.enter="memberPopupSearch(searchData,'name','give')"
                                    />
                                  </li>
                                </ul>
                                <ul class="content">
                                  <li class="item button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          @click.native="memberPopupSearch(searchData,'name','give')"
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title">
                                      회원명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ giver.memberName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      생년월일
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group ">
                                          {{ giver.birthDay }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      음/양
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group ">
                                          {{ giver.lunarCode }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      성별
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ giver.sexCode }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      핸드폰번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group ">
                                          {{giver.hpNo | getFormattedPhoneNumber }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      SMS 수신동의
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group ">
                                          {{giver.smsRecptnDiv}}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">취득일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.acqsDate }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">거래가액</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.acqsAmt | numberWithCommas }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      회원번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ giver.memberNo | memberNoFormatter }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      회원(권)ID
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{ giver.memberId || giver.membershipId }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">회원권상태</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item ">
                                          {{ giver.membershipStatus }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                    </div>
                                    <div class="content">
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.memberDiv }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">
                                      회원등급
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.memberGrade }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">법인구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.cprDiv }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">가입차수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.entryOrder }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">사업자번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.bizNo | bizNoFormatter }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">법인번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.cprNo | regNoFormatter }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">법인명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ giver.cprName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">분양금액</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{giver.issueAmt | numberWithCommas}}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field" style="width: calc((100% / 4) * 2 + 1px)">
                                    <div class="title" style="width: 118px">자택주소</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{giver.homePostNo}}
                                          {{giver.homeAddr1}}
                                          {{giver.homeAddr2}}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field" style="width: calc((100% / 4) * 2 + 1px)">
                                    <div class="title" style="width: 117px">회사주소</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{giver.companyPostNo}}
                                          {{giver.companyAddr1}}
                                          {{giver.companyAddr2}}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 양수자 -->
                      <section class="article-section section-0102" v-if="renameDiv !== 'OUT'">
                        <div class="section-header">
                          <div class="header-left">
                            <ul class="lookup-condition">
                              <li class="field">
                                <div class="header-title">양수자</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <div class="title required">
                                      회원명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input" style="width: 94px">
                                          <div class="form">
                                            <input-text
                                                ref="takeMemberName"
                                                id="input-name"
                                                name="name"
                                                v-model="taker.memberName"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                @keydown.enter="memberPopupSearch(taker.memberName,'name','take')"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button" >
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                :disabled="isNAME"
                                                v-on:click.native="memberPopupSearch(taker.memberName,'name','take')"
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field dateOfBirth" style="width: calc( (100% / 4) * 2 + 1px )">
                                    <div class="title required" style="width: 115px">
                                      생년월일
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li
                                            class="item form-group birthday"
                                            style="width: 231px; height: 23px;"
                                        >
                                          <div class="form">
                                            <input-birthday
                                                ref="takeBirthDay"
                                                name="birthday"
                                                type="body-data"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.birthDay"
                                            />
                                          </div>
                                        </li>
                                        <li
                                            class="item form-group yang-yin" style="width: 118px"
                                        >
                                          <!-- 포커스 : focus -->
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                    type="radio"
                                                    ref="lunarCode"
                                                    name="lunarCode"
                                                    value="SOLAR"
                                                    :readonly="isNAME"
                                                    :disabled="isNAME"
                                                    v-model="taker.lunarCode"
                                                />
                                                <i></i>
                                                <span class="label">
                                                  양력
                                                </span>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                    type="radio"
                                                    ref="lunarCode"
                                                    name="lunarCode"
                                                    value="LUNAR"
                                                    :readonly="isNAME"
                                                    :disabled="isNAME"
                                                    v-model="taker.lunarCode"
                                                />
                                                <i></i>
                                                <span class="label">
                                                  음력
                                                </span>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">성별</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="takeSexCode"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                :dataSource="dropDownOption.sexCode"
                                                v-model="taker.sexCode"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      핸드폰번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div
                                              class="form"
                                          >
                                            <component-telephone
                                                ref="takeTelephone"
                                                :max-length="11"
                                                name="phoneNumber"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.hpNo"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      SMS 수신동의
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="takeSmsRecptnDiv"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :dataSource="dropDownOption.smsRecptnDiv"
                                                :readonly="isUSER || isNAME"
                                                :disabled="isUSER || isNAME"
                                                v-model="taker.smsRecptnDiv"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">가입차수</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="takeEntryOrder"
                                                name="entryOrder"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :readonly="isUSER || isNAME"
                                                :disabled="isUSER || isNAME"
                                                :dataSource="dropDownOption.entryOrder"
                                                v-model="taker.entryOrder"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">취득일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="takeAcqsDate"
                                                name="acquireDate"
                                                :isBindingEmptyString="true"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                type="body-data"
                                                v-model="taker.acqsDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="takeMemberDiv"
                                                name="memberDivision"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :readonly="isUSER || isNAME"
                                                :disabled="isUSER || isNAME"
                                                :dataSource="dropDownOption.memberDiv"
                                                v-model="taker.memberDiv"
                                                @change="onChangeMemberDiv"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      회원번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                                ref="takeMemberNo"
                                                mask="00-0000-00"
                                                :readonly="isUSER || isNAME"
                                                :disabled="isUSER || isNAME"
                                                v-model="taker.memberNo"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">
                                      회원등급
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="takeMemberGrade"
                                                name="memberGrade"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :readonly="isUSER || isNAME"
                                                :disabled="isUSER || isNAME"
                                                :dataSource="dropDownOption.memberGrade"
                                                v-model="taker.memberGrade"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">거래가액</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                                ref="acquireAmount"
                                                name="acquireAmount"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.acqsAmt"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title required">법인구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="takeCprDiv"
                                                name="corporateDivision"
                                                :allowFiltering="false"
                                                :fields="commonCodeFields"
                                                cssClass="body-data-dropdown"
                                                :readonly="isUSER || isNAME"
                                                :disabled="isUSER || isNAME"
                                                :dataSource="dropDownOption.cprDiv"
                                                v-model="taker.cprDiv"
                                                @change="onChangeCprDiv"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title" :class="taker.cprDiv === 'CPR' && 'required'">
                                      법인명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input" style="width: 94px">
                                          <div class="form">
                                            <input-text
                                                ref="takeCprName"
                                                name="corporationName"
                                                v-model="taker.cprName"
                                                @keydown.enter="onCorporationPopupSearch"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button" >
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                  button-div="GET"
                                                  :disabled="isNAME"
                                                  v-on:click.native="onCorporationPopupSearch"
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div
                                        class="title"
                                        :class="taker.cprDiv === 'CPR' && 'required'"
                                    >
                                      사업자번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-maskedtextbox
                                                ref="takeBizNo"
                                                name="businessNoInput"
                                                mask="000-00-00000"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.bizNo"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <div class="title">만기일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                ref="expireDate"
                                                name="expireDate"
                                                :isBindingEmptyString="true"
                                                type="body-data"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.expireDate"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field address" style="width: calc((100% / 4) * 2 + 1px)">
                                    <div class="title required" style="width: 118px">자택주소</div>
                                    <div class="content">
                                      <ul class="row address">
                                        <li
                                            class="item form-group post"
                                            style="width: 118px"
                                        >
                                          <div class="form">
                                            <input-text
                                                ref="homePostalCode"
                                                name="homePostalCode"
                                                maxlength="10"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.homePostNo"
                                            />
                                          </div>
                                        </li>
                                        <li
                                            class="item form-group button"
                                        >
                                          <ul class="button">
                                            <li>
                                              <erp-button
                                                button-div="GET"
                                                :disabled="isNAME"
                                                @click.native="onPostCodeClick('home')"
                                              >
                                                우편번호
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <ul class="row" style="display: flex;">
                                        <li
                                            class="item form-group input"
                                        >
                                          <div class="form">
                                            <input-text
                                                ref="homeAddress"
                                                name="homeAddress"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.homeAddr1"
                                            />
                                          </div>
                                        </li>
                                        <li
                                            class="item form-group input"
                                        >
                                          <div class="form">
                                            <input-text
                                                ref="homeAdditionalAddress"
                                                name="homeAdditionalAddress"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.homeAddr2"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field address" style="width: calc((100% / 4) * 2 + 1px)">
                                    <div class="title" style="width: 117px" :class="taker.cprDiv === 'CPR' && 'required'">회사주소</div>
                                    <div class="content">
                                      <ul class="row address">
                                        <li
                                            class="item form-group post"
                                            style="width: 118px"
                                        >
                                          <div class="form">
                                            <input-text
                                                ref="companyPostalCode"
                                                name="companyPostNo"
                                                maxlength="10"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.companyPostNo"
                                            />
                                          </div>
                                        </li>
                                        <li
                                            class="item form-group button"
                                        >
                                          <ul class="button">
                                            <li>
                                              <erp-button
                                                button-div="GET"
                                                :disabled="isNAME"
                                                @click.native="onPostCodeClick('company')"
                                              >
                                                우편번호
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                      <ul class="row" style="display: flex;">
                                        <li
                                            class="item form-group input"
                                        >
                                          <div class="form">
                                            <input-text
                                                ref="companyAddress"
                                                name="companyAddr1"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.companyAddr1"
                                            />
                                          </div>
                                        </li>
                                        <li
                                            class="item form-group input"
                                        >
                                          <div class="form">
                                            <input-text
                                                ref="companyAdditionalAddress"
                                                name="companyAddr2"
                                                :readonly="isNAME"
                                                :disabled="isNAME"
                                                v-model="taker.companyAddr2"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="save"
                :disabled="isConfirmed"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="hide">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
        ref="memberSelectPopup"
        v-if="isMemberSelectPopupOpen"
        @popupConfirmed="onPopupConfirmed"
    />
    <daum-postcode-popup
        v-if="visibleDaumPostcodePopup"
        ref="daumPostcodePopup"
        :position="{ x: 'center', y: 'center' }"
        :isModal="true"
        @popupClose="daumPostcodePopupClosed"
        @popupConfirmed="daumPostcodePopupConfirmed"
    />
    <corporation-search-popup
        v-if="isCorporationSelectPopupOpen"
        ref="corporationSearchPopup"
        :cprName="taker.cprName"
        @confirmed="onCorporationSearchPopupConfirmed"
    />
  </div>
</template>

<script>
import daumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import InputBirthday from "@/components/common/datetime/InputBirthday.vue";
import InputNumber from "@/components/common/InputNumber";
import componentTelephone from "@/components/ComponentTelephone.vue";
import InputText from "@/components/common/text/InputText";
import InputDate from "@/components/common/datetime/InputDate.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  commonCodesGetCommonCode, commonCodesGetCommonCodeAttrbByCodeAndIdx, commonCodesGetCommonCodeByIdx,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetComName
} from "@/utils/commonCodes";
import {DATE_FORMAT_YYYY_MM_DD, getFormattedDate} from "@/utils/date";
import {validateFormRefs} from '@/utils/formUtil';
import {getFormattedPhoneNumber} from "@/utils/string";
import {numberWithCommas} from "@/utils/number";
import {bizNoFormatter, memberNoFormatter, regNoFormatter} from "@/utils/formatter";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import CorporationSearchPopup from "./CorporationSearchPopup.vue";


export default {
  name: "NameChangeApplicationConfirmationPopup",
  components: {
    CorporationSearchPopup,
    InputText,
    InputDate,
    InputNumber,
    componentTelephone,
    InputBirthday,
    memberSelectPopup,
    daumPostcodePopup,
    ErpButton,
  },
  mixins: [messagePopupDialogMixin],
  created() {
    this.dropDownOption.renameDiv = commonCodesGetCommonCode("RENAME_DIV", true);
    this.dropDownOption.sexCode = commonCodesGetCommonCode("SEX_CODE", true);
    this.dropDownOption.smsRecptnDiv = commonCodesGetCommonCode("SMS_RECPTN_DIV", true);
    this.dropDownOption.membershipStatus = commonCodesGetCommonCode("MEMBERSHIP_STATUS", true);
    this.dropDownOption.memberDiv = commonCodesGetCommonCodeByIdxAttrb("MEMBER_DIV",  2, "G1");
    this.dropDownOption.memberGrade = commonCodesGetCommonCode("MEMBER_GRADE", true);
    this.dropDownOption.cprDiv = commonCodesGetCommonCode("CPR_DIV", true);
    this.dropDownOption.entryOrder = commonCodesGetCommonCode("ENTRY_ORDER", true);
  },
  data() {
    return {
      isCorporationSelectPopupOpen: false,
      companyComboboxDataSource: [],
      isMemberSelectPopupOpen:false,
      visibleDaumPostcodePopup:false,
      renameDiv:'NEW',
      propsData:null,
      searchData: null,
      postcodeTarget:null,
      detailsData: {},
      giver: {},
      taker: {
        lunarCode:"SOLAR",
        birthDay: null,
        acqsAmt: 0,
        memberNo: "",
        cprName: "",
        bizNo: "",
        expireDate: null,
      },
      dropDownOption: {
        renameDiv: [],
        sexCode: [],
        smsRecptnDiv: [],
        membershipStatus: [],
        memberDiv: [],
        memberGrade: [],
        cprDiv: [],
        entryOrder: [],
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      isConfirmed: false,
    };
  },
  filters: {
    numberWithCommas,
    getFormattedPhoneNumber,
    memberNoFormatter,
    regNoFormatter,
    bizNoFormatter,
  },
  watch: {
    renameDiv() {
      const isOnlyRegularMemberDivCondition = this.isNEW || this.isTRANS || this.isINHER || this.isGIFT;
      this.dropDownOption.memberDiv = isOnlyRegularMemberDivCondition
        ? commonCodesGetCommonCodeByIdxAttrb("MEMBER_DIV",  2, "G1")
        : commonCodesGetCommonCode("MEMBER_DIV", true);
    },
    "detailsData.frstIssueDate": function(frstIssueDate) {
      if(this.isNEW) {
        this.taker.acqsDate = frstIssueDate;
      }
    },
    "detailsData.frstIssueAmt": function(frstIssueAmt) {
      if(this.isNEW) {
        this.taker.acqsAmt = frstIssueAmt;
      }
    },
  },
  computed: {
    isNEW() {
      return this.renameDiv === 'NEW'; // 신규입회
    },
    isTRANS() {
      return this.renameDiv === "TRANS"; // 명의변경
    },
    isUSER() {
      return this.renameDiv === "USER"; // 이용자변경
    },
    isINHER() {
      return this.renameDiv === 'INHER'; // 상속
    },
    isGIFT() {
      return this.renameDiv === 'GIFT'; // 증여
    },
    isNAME() {
      return this.renameDiv === "NAME"; // 상호변경
    },
    isOUT() {
      return this.renameDiv === 'OUT'; // 탈회
    },

    smsRecptnDivDefault() {
      return this.dropDownOption.smsRecptnDiv.find(({ defaultFlag }) =>  defaultFlag).comCode;
    },
    cprDivDefault() {
      return this.dropDownOption.cprDiv.find(({ defaultFlag }) =>  defaultFlag).comCode;
    },
    entryOrderDefault() {
      return this.dropDownOption.entryOrder.find(({ defaultFlag }) =>  defaultFlag).comCode;
    },
    expireDateDefault() {
      return moment('2099-12-31').format(DATE_FORMAT_YYYY_MM_DD);
    },
    validateRefList() {
      const refList = {
        requestDate: { required: true },
        renameDate: { required: true },
        frstIssueDate: { required: this.isNEW },
        frstIssueAmt: {
          required: this.isNEW,
        },
        giveRenameFee: { required: this.renameDiv === "OUT" },
        takeRenameFee: { required: this.renameDiv !== "OUT" },
        takeRemarks: { required: true },
        takeMemberName: { required: true },
        takeBirthDay: { required: true },
        takeSexCode: { required: true },
        takeTelephone: {
          required: true,
          custom: {
            method(value) {
              return value.length === 11;
            },
            message: "핸드폰번호를 제대로 입력해주세요."
          }
        },
        takeSmsRecptnDiv: { required: true },
        takeEntryOrder: { required: true },
        takeAcqsDate: { required: true },
        takeMemberDiv: { required: true },
        takeMemberNo: { required: true },
        takeMemberGrade: { required: true },
        takeCprDiv: { required: true },
        takeMemberId: { required: this.renameDiv !== 'NEW' },
        takeMembershipStatus: { required: true },
        // takeBizName: { required: this.taker.cprDiv === 'CPR' },
        takeBizNo: { required: this.taker.cprDiv === 'CPR' },
        takeCprName: { required: this.taker.cprDiv === 'CPR' },
        // takeCprNo: { required: this.taker.cprDiv === 'CPR' },
        homePostalCode: { required: true },
        homeAddress: { required: true },
        companyPostalCode: { required: this.taker.cprDiv === 'CPR' },
        companyAddress: { required: this.taker.cprDiv === 'CPR' },
      };

      if(!this.isUSER) {
        refList.takeMemberNo.custom = {
          method(value) {
            return `${value}`.endsWith('00');
          },
          message: "회원번호는 00으로 끝나야 합니다.",
        };
      }
      if(this.isNEW) {
        refList.frstIssueAmt.custom = {
          method(value) {
            return value > 0;
          },
          message: "분양금액은 0보다 커야 합니다.",
        };
      }

      return refList;
    }
  },
  methods: {
    getFormattedDate,
    validateFormRefs,
    /**
     * 팝업이 보여졌을 때 동작
     * @param renameDivision
     * @param id
     * @returns {Promise<void>}
     * id가 존재 -> 리스트에서 타고옴
     * id가 없음 -> 신규
     * erp code reviewer test111
     */
    async show(renameDivision = null ,id = null, confirmFlag) {
      const isRegisted = !!id;
      this.propsData = id;
      this.isConfirmed = confirmFlag;

      // 신규입회등록 셋팅
      if(!isRegisted) {
        this.giver = {
          saleNo: "NA",
        };
        this.detailsData = {
          requestDate:getFormattedDate(new Date()),
          renameDate:getFormattedDate(new Date()),
          renameFee: null,
          confirmFlag: "",
          confirmName: "",
          confirmDt: "",
          remarks: "신규입회",
          frstIssueDate: this.isNEW ? getFormattedDate(new Date()) : "",
          frstIssueAmt: null,
        };
      }
      // 이미 등록된 회원정보 셋팅
      if(isRegisted) {
        this.searchData = null;
        this.renameDiv = renameDivision;
        this.detailsData = await GolfErpAPI.getNameChangeApplicationConfirmationDetails(id);
        const giverData = this.detailsData?.tgMembershipRenames.find((e) => e.giveTakeDiv === "GIVE") || {};
        const takerData = this.detailsData?.tgMembershipRenames.find((e) => e.giveTakeDiv === "TAKE") || {};


        this.giver = {
          ...giverData,
          sexCode: commonCodesGetComName('SEX_CODE', giverData.sexCode),
          lunarCode: commonCodesGetComName('LUNAR_CODE',giverData.lunarCode),
          cprDiv: commonCodesGetComName('CPR_DIV',giverData.cprDiv),
          memberDiv: commonCodesGetComName('MEMBER_DIV',giverData.memberDiv),
          memberGrade: commonCodesGetComName('MEMBER_GRADE',giverData.memberGrade),
          entryOrder: commonCodesGetComName('ENTRY_ORDER',giverData.entryOrder),
          smsRecptnDiv: commonCodesGetComName('SMS_RECPTN_DIV',giverData.smsRecptnDiv),
          membershipStatus: commonCodesGetComName('MEMBERSHIP_STATUS',giverData.membershipStatus),
          memberId: giverData.memberId,
          membershipId: giverData.membershipId,
        };

        this.taker = {
          ...takerData,
          bizNo: takerData.bizNo,
          bizId: takerData.bcncId ? takerData.bcncId : "",
          // cprNo: takerData.cprNo,
          expireDate: giverData.expireDate ? giverData.expireDate : this.expireDateDefault,
          cprName: takerData.cprName,
        };

        if(!this.isNEW) {
          this.detailsData.frstIssueAmt = this.giver.issueAmt;
          this.detailsData.frstIssueDate = this.giver.acqsDate;
        }
      }
    },
    refresh() {
      this.show(this.renameDiv,this.propsData, this.isConfirmed);
    },
    dialogClose() {
      console.log("명의변경구분 팝업 dialogClose");
      this.$emit("close");
    },
    handleSearch() {
      this.$emit('search');
    },
    daumPostcodePopupClosed() {
      this.visibleDaumPostcodePopup = false;
    },
    daumPostcodePopupConfirmed(data) {
      if (this.postcodeTarget === "home") {
        this.taker.homePostNo = data.zonecode;
        this.taker.homeAddr1 = data.address;
        this.taker.homeAddr2 = "";

      }
      if (this.postcodeTarget === "company") {
        this.taker.companyPostNo = data.zonecode;
        this.taker.companyAddr1 = data.address;
        this.taker.companyAddr2 = "";
      }

      this.daumPostcodePopupClosed();
    },
    memberPopupSearch(args,type,division) {
      this.isMemberSelectPopupOpen = true;
      this.searchData = null;
      const memberData = {};
      memberData.nomDataViewFlag = division !== 'give';
      memberData.renameDiv = this.renameDiv;
      memberData.isGiver = division === 'give';
      memberData.isTaker = division === 'take';
      type === 'name' && (memberData.memberNameNo = args);
      type === 'tel' && (memberData.contactTelIdx = args);
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    async onPopupConfirmed({popupData, selectedRowData}) {
      const { membershipId } = selectedRowData;
      const { isGiver, isTaker } = popupData;

      if(membershipId) {
        const isSeizureMember = await GolfErpAPI.getSeizureCheck({ membershipId });
        if(isSeizureMember) {
          this.errorToast("회원권 압류 정보가 존재합니다. 해지 후 명의개서를 진행해주세요.");
          return;
        }
      }

      isGiver && this.setGiverFromSelectedRow(selectedRowData);
      isTaker && await this.setTakerFromSelectedRow(selectedRowData);
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.onCloseClicked();
        this.isMemberSelectPopupOpen = false;
      });

    },
    setGiverFromSelectedRow(selectedRowData) {
      console.log("양도자 선택함", selectedRowData);
      this.giver = {
        saleNo: selectedRowData.saleNo,
        cprDiv: commonCodesGetComName('CPR_DIV',selectedRowData.cprDiv),
        cprDivCode: selectedRowData.cprDiv,
        memberNo: selectedRowData.memberNo,
        memberDiv: commonCodesGetComName('MEMBER_DIV',selectedRowData.memberDiv),
        memberDivCode: selectedRowData.memberDiv,
        memberGrade: commonCodesGetComName('MEMBER_GRADE',selectedRowData.memberGrade),
        memberGradeCode: selectedRowData.memberGrade,
        entryOrder: commonCodesGetComName('ENTRY_ORDER',selectedRowData.entryOrder),
        memberName: selectedRowData.memberName,
        birthDay: selectedRowData.birthDay,
        lunarCode: commonCodesGetComName('LUNAR_CODE',selectedRowData.lunarCode),
        hpNo: selectedRowData.hpNo,
        smsRecptnDiv: commonCodesGetComName('SMS_RECPTN_DIV',selectedRowData.smsRecptnDiv),
        sexCode: commonCodesGetComName('SEX_CODE',selectedRowData.sexCode),
        homePostNo: selectedRowData.homePostNo,
        homeAddr1: selectedRowData.homeAddr1,
        homeAddr2: selectedRowData.homeAddr2,
        companyPostNo: selectedRowData.companyPostNo,
        companyAddr1: selectedRowData.companyAddr1,
        companyAddr2: selectedRowData.companyAddr2,
        cprName: selectedRowData.cprName,
        bizNo: selectedRowData.bizNo,
        cprNo: selectedRowData.cprNo,
        issueAmt: selectedRowData.issueAmt,
        acqsAmt: selectedRowData.acqsAmt,
        acqsDate: selectedRowData.acqsDate,
        expireDate: selectedRowData.expireDate ? selectedRowData.expireDate : this.expireDateDefault,
        membershipStatus: commonCodesGetComName('MEMBERSHIP_STATUS',selectedRowData.membershipStatus),
        membershipId: selectedRowData.membershipId,
        memberId: selectedRowData.memberId,
      };
      this.detailsData.membershipId = selectedRowData.membershipId;
      this.detailsData.frstIssueAmt = selectedRowData.issueAmt;
      this.detailsData.frstIssueDate = selectedRowData.acqsDate;
      switch (this.renameDiv) {
        case "USER":
          this.taker.smsRecptnDiv = this.smsRecptnDivDefault;
          // this.taker.acqsDate = this.acqsDateDefault;
          this.taker.memberNo = selectedRowData.memberNo;
          this.taker.memberDiv = selectedRowData.memberDiv;
          this.taker.memberGrade = selectedRowData.memberGrade;
          this.taker.cprDiv = selectedRowData.cprDiv;
          this.taker.entryOrder = selectedRowData.entryOrder;
          break;
        case "NAME":
          this.taker.memberName = selectedRowData.memberName;
          this.taker.birthDay = selectedRowData.birthDay;
          this.taker.lunarCode = selectedRowData.lunarCode;
          this.taker.sexCode = selectedRowData.sexCode;
          this.taker.hpNo = selectedRowData.hpNo;
          this.taker.smsRecptnDiv = selectedRowData.smsRecptnDiv;
          this.taker.entryOrder = selectedRowData.entryOrder;
          // this.taker.acqsDate = selectedRowData.acqsDate;
          this.taker.memberNo = selectedRowData.memberNo;
          this.taker.memberDiv = selectedRowData.memberDiv;
          this.taker.memberGrade = selectedRowData.memberGrade;
          this.taker.acqsAmt = selectedRowData.acqsAmt;
          this.taker.cprDiv = selectedRowData.cprDiv;
          this.taker.rwrtDate = selectedRowData.rwrtDate;
          this.taker.expireDate = selectedRowData.expireDate;
          this.taker.homePostNo = selectedRowData.homePostNo;
          this.taker.homeAddr1 = selectedRowData.homeAddr1;
          this.taker.homeAddr2 = selectedRowData.homeAddr2;
          this.taker.companyPostNo = selectedRowData.companyPostNo;
          this.taker.companyAddr1 = selectedRowData.companyAddr1;
          this.taker.companyAddr2 = selectedRowData.companyAddr2;
          this.taker.bizNo = selectedRowData.bizNo;
          break;
        case "TRANS":
        case "INHER":
        case "GIFT":
          // this.taker.acqsDate = this.acqsDateDefault;
          this.taker.cprDiv = this.cprDivDefault;
          this.taker.memberNo = selectedRowData.memberNo;
          this.taker.memberDiv = selectedRowData.memberDiv;
          this.taker.smsRecptnDiv = this.smsRecptnDivDefault;
          this.taker.entryOrder = selectedRowData.entryOrder;
          break;
      }
    },
    /**
     * 기존 회원을 양수자로 할 경우 회원번호는 신규발급되므로 기존 등록된 정보 초기화
     */
    async setTakerFromSelectedRow(selectedRowData) {
      console.log("양수자 선택함");
      this.taker = {
        ...this.taker,
        memberName: selectedRowData.memberName,
        birthDay: selectedRowData.birthDay,
        lunarCode: selectedRowData.lunarCode,
        sexCode: selectedRowData.sexCode,
        hpNo: selectedRowData.hpNo,
        memberId: selectedRowData.memberId ? selectedRowData.memberId : "",
        expireDate: selectedRowData.expireDate ? selectedRowData.expireDate : this.expireDateDefault,
      };
      switch (this.renameDiv) {
        case "NEW":
          this.taker.smsRecptnDiv = this.smsRecptnDivDefault;
          this.taker.entryOrder = this.entryOrderDefault;
          this.taker.cprDiv = this.cprDivDefault;
          this.taker.cprName = selectedRowData.cprName ? selectedRowData.cprName : "";
          this.taker.bizNo = selectedRowData.bizNo ? selectedRowData.bizNo : "";
          this.taker.expireDate = selectedRowData.expireDate ? selectedRowData.expireDate : this.expireDateDefault;
          this.taker.homePostNo = selectedRowData.homePostNo ? selectedRowData.homePostNo : "";
          this.taker.homeAddr1 = selectedRowData.homeAddr1 ? selectedRowData.homeAddr1 : "";
          this.taker.homeAddr2 = selectedRowData.homeAddr2 ? selectedRowData.homeAddr2 : "";
          this.taker.companyPostNo = selectedRowData.companyPostNo ? selectedRowData.companyPostNo : "";
          this.taker.companyAddr1 = selectedRowData.companyAddr1 ? selectedRowData.companyAddr1 : "";
          this.taker.companyAddr2 = selectedRowData.companyAddr2 ? selectedRowData.companyAddr2 : "";
          break;
        case "USER":
          this.taker.homePostNo = selectedRowData.homePostNo ? selectedRowData.homePostNo : "";
          this.taker.homeAddr1 = selectedRowData.homeAddr1 ? selectedRowData.homeAddr1 : "";
          this.taker.homeAddr2 = selectedRowData.homeAddr2 ? selectedRowData.homeAddr2 : "";
          this.taker.companyPostNo = selectedRowData.companyPostNo ? selectedRowData.companyPostNo : "";
          this.taker.companyAddr1 = selectedRowData.companyAddr1 ? selectedRowData.companyAddr1 : "";
          this.taker.companyAddr2 = selectedRowData.companyAddr2 ? selectedRowData.companyAddr2 : "";
          break;
        case "NAME":
          break;
        case "TRANS":
        case "INHER":
        case "GIFT":
          // this.taker.entryOrder = this.entryOrderDefault;
          // this.taker.acqsDate = this.acqsDateDefault;
          this.taker.smsRecptnDiv = this.smsRecptnDivDefault;
          this.taker.cprDiv = this.cprDivDefault;
          this.taker.homePostNo = selectedRowData.homePostNo ? selectedRowData.homePostNo : "";
          this.taker.homeAddr1 = selectedRowData.homeAddr1 ? selectedRowData.homeAddr1 : "";
          this.taker.homeAddr2 = selectedRowData.homeAddr2 ? selectedRowData.homeAddr2 : "";
          this.taker.companyPostNo = selectedRowData.companyPostNo ? selectedRowData.companyPostNo : "";
          this.taker.companyAddr1 = selectedRowData.companyAddr1 ? selectedRowData.companyAddr1 : "";
          this.taker.companyAddr2 = selectedRowData.companyAddr2 ? selectedRowData.companyAddr2 : "";
          break;
      }

      this.detailsData.saleNo = selectedRowData.saleNo;
    },
    onPostCodeClick(postcodeTarget) {
      this.postcodeTarget = postcodeTarget;
      this.visibleDaumPostcodePopup = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    onChangeRenameDiv({ value }) {
      this.detailsData.remarks = commonCodesGetComName("RENAME_DIV", value, true);
    },
    onChangeCprDiv({value}) {
      if (value === "FIT") {
        this.taker.cprName = null;
        // this.taker.cprNo = null;
        // this.taker.bizName = null;
        this.taker.bizNo = null;
        this.taker.bizId = null;
        // this.taker.companyPostNo = null;
        // this.taker.companyAddr1 = null;
        // this.taker.companyAddr2 = null;
      }
    },
    onCorporationPopupSearch() {
      this.isCorporationSelectPopupOpen = true;
    },
    onCorporationSearchPopupConfirmed(corporationInfo) {
      const { bcncName, bizNo, postNo, addr1, addr2 } = corporationInfo;
      this.taker.cprName = bcncName;
      this.taker.bizNo = bizNo;
      this.taker.companyPostNo = postNo;
      this.taker.companyAddr1 = addr1;
      this.taker.companyAddr2 = addr2;
      this.$nextTick(() => {
        this.$refs.corporationSearchPopup.hide();
        this.isCorporationSelectPopupOpen = false;
      });
    },
    hide() {
      this.$refs.nameChangeApplicationPopup.hide();
    },
    async onChangeMemberDiv(event) {
      const { value: memberDivCode, e: eventFromUser } = event;
      if(!eventFromUser) {return;}
      if(this.isNEW || this.isTRANS || this.isINHER || this.isGIFT) {
        try {
          const frontMembershipNumber = commonCodesGetCommonCodeAttrbByCodeAndIdx("MEMBER_DIV", memberDivCode, 8);
          const middleMembershipNumber = await GolfErpAPI.getMiddleMembershipNumber({ code: frontMembershipNumber });
          if(middleMembershipNumber === '10000'){
            this.errorToast("회원번호가 유효하지 않습니다.");
            this.taker.memberNo = frontMembershipNumber;
            return;
          }
          const regularMemberDefaultMembershipEndNumber = "00";
          this.taker.memberNo = frontMembershipNumber + middleMembershipNumber + regularMemberDefaultMembershipEndNumber;
        } catch (e) {
          this.errorToast("회원번호가 유효하지 않습니다.");
        }
      }
    },
    async save() {
      if(!this.validateFormRefs(this.validateRefList)) { return; }

      try {
        const postData = {};
        const isModify = !!this.propsData;

        if(isModify) {
          postData.tgMembershipRenameRequest = {
            renameRequestId: this.detailsData?.renameRequestId,
            requestDate: this.detailsData.requestDate,
            renameDiv: this.detailsData.renameDiv,
            remarks: this.detailsData.remarks,
            renameDate: this.detailsData.renameDate,
            renameFee: this.detailsData.renameFee,
            frstIssueDate: this.detailsData.frstIssueDate,
            frstIssueAmt: this.detailsData.frstIssueAmt,
          };
          postData.tgMembershipRenameByGive = {
            renameId: this.giver.renameId,
            membershipId: this.giver.membershipId,
          };
          postData.tgMembershipRenameByTake = {
            ...this.taker,
            saleNo: this.isNEW ? 'NA' : this.taker.saleNo,
          };
        }
        if(!isModify) {
          postData.tgMembershipRenameRequest = {
            renameRequestId: this.detailsData?.renameRequestId,
            requestDate: this.detailsData.requestDate,
            renameDiv: this.renameDiv,
            remarks: this.detailsData.remarks,
            renameDate: this.detailsData.renameDate,
            renameFee: this.detailsData.renameFee,
            frstIssueDate: this.detailsData.frstIssueDate,
            frstIssueAmt: this.detailsData.frstIssueAmt,
          };
          postData.tgMembershipRenameByTake = !this.isOUT
              ? {
                ...this.taker,
                saleNo: this.isNEW ? 'NA' : this.giver.saleNo,
                issueAmt: this.isNEW ? this.taker.acqsAmt : this.giver.acqsAmt,
                memberId: null,
              }
              : null;

          postData.tgMembershipRenameByGive = !this.isNEW
              ? { membershipId: this.giver.membershipId, renameId: this.giver.renameId }
              : null;
        }
        await GolfErpAPI.patchNameChangeApplicationConfirmationDetail(postData);
        this.handleSearch();
      } catch (e) {
        this.errorToast("저장에 실패하였습니다.");
      }
    }
  },
};
</script>

<style scoped>
body .window.couponKindDetail .windowContent .article-section .body-data .field { width: calc( (100% / 4) + 1px ) }
body .body-data .data-content .content { width: calc((100% - 100px) + 1px) }
body .body-data .data-content .row { border-bottom: none }
body .body-data .data-content .row.address { border-bottom: 1px solid #e0e0e0 }
</style>