<template>
  <div>
    <ejs-dialog
      ref="daumPostcodePopupDialog"
      id="daumPostcodePopupDialog"
      header="주소찾기"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="640"
      height="500"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <vue-daum-postcode @complete="onConfirmClicked" />
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import VueDaumPostcode from "vue-daum-postcode";

Vue.use(VueDaumPostcode);

export default {
  name: "daumPostcodePopup",
  data() {
    return {
      animationSettings: { effect: "None" },
      classCodeFields: { text: "className", value: "classCode" },
      numberTemplate() {
        // TODO : 공통 모듈로??
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      boolParams: { params: { checked: true } },
      isInit: true,
    };
  },
  methods: {
    showPopup() {
      this.$refs.daumPostcodePopupDialog.show();
    },
    dialogClose() {
      this.$emit("popupClosed", null);
    },
    onCloseClicked() {
      this.$refs.daumPostcodePopupDialog.hide();
    },
    onConfirmClicked(data) {
      this.$emit("popupConfirmed", data);
    },
  },
};
</script>
