import request from "@/utils/request";

/**
 * @return Promise
 */
export function getAccountInfo(bcncNamebizNo, bcncDiv, useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/bcnc-code/bcnc-info",
    method: "get",
    params: {
      bcncNamebizNo,
      bcncDiv,
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function addAccountInfo(bcncCodeInfo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/bcnc-code/bcnc-info",
    method: "post",
    data: {
      bcncCodeInfo,
    },
  });
}

/**
 * @return Promise
 */
export function editAccountInfo(bcncCodeInfo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/bcnc-code/bcnc-info",
    method: "put",
    data: {
      bcncCodeInfo,
    },
  });
}

/**
 * @return Promise
 */
export function deleteAccountInfo(bcncCodeInfoList) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/bcnc-code/bcnc-info",
    method: "delete",
    data: {
      bcncCodeInfoList,
    },
  });
}

/**
 * @return Promise
 */
export function checkDuplicate(bcncId, { bcncName, bizNo, cprNo }) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code_management/bcnc-code/bcnc-duplicates",
    method: "get",
    params: {
      bcncId,
      bcncName,
      bizNo,
      cprNo,
    },
  });
}
