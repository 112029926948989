<template>
  <div>
    <ejs-dialog
        ref="corporationSearchPopup"
        header="법인검색"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        width="600"
        height="600"
        @close="hide"
        @open="onOpen"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <ul class="lookup-condition">
                        <li class="field">
                          <div class="title">거래처명/사업자등록번호</div>
                          <ul class="content">
                            <li class="item">
                              <input-text
                                  ref="searchCprName"
                                  id="searchCprName"
                                  v-model="searchCprName"
                                  @keydown.enter="onSearchCprName"
                              />
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <li class="search">
                          <erp-button
                              button-div="GET"
                              :is-shortcut-button="true"
                              v-on:click.native="onSearchCprName"
                          >
                            조회
                          </erp-button>
                        </li>
                        <li class="save">
                          <erp-button
                              button-div="SAVE"
                              :is-shortcut-button="true"
                              v-on:click.native="onAddCorporation"
                          >
                            추가
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="section-body" class="section-body">
                    <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridOptions"
                        @recordClick="onRecordClick"
                        @keyPressed="onKeyPressed"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="hide">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <corporation-registration-popup
        v-if="isCorporationRegistrationPopupOpen"
        ref="corporationRegistrationPopup"
        @registPopupClose="registPopupClose"
    />
  </div>

</template>
<script>
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import {Filter, Page} from "@syncfusion/ej2-vue-grids";
import InputText from "@/components/common/text/InputText.vue";
import { getAccountInfo } from "@/api/accountRegistration";
import CorporationRegistrationPopup from "./CorporationRegistrationPopup.vue";

export default {
  name: "CorporationSearchPopup",
  components: {InputText, ErpButton, EjsGridWrapper, CorporationRegistrationPopup},
  props: {
    cprName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchCprName: this.cprName,
      corporationList: [],
      isCorporationRegistrationPopupOpen: false,
    };
  },
  computed: {
    gridOptions() {
      return {
        provides: [
          Filter,
          Page,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowEditing: false,
        allowExcelExport: false,
        allowPaging: true,
        noColumnWidth: 20,
        isAutoSelectRow: true,
        pageSettings: { pageSize: 50 },
        dataSource: this.corporationList,
        columns: [
          {
            field: "bcncName",
            headerText: "거래처명",
            type: "string",
            width: 30,
          },
          {
            field: "bizNo",
            headerText: "사업자등록번호",
            type: "string",
            width: 30,
          },
        ],
      };
    },
  },
  async created() {
    this.searchCprName && await this.onSearchCprName();
  },
  methods: {
    hide() {
      this.$refs.corporationSearchPopup.hide();
    },
    async onSearchCprName() {
      const { value: { tsBcncList } } = await getAccountInfo(
        this.searchCprName,
          "",
          true,
      );
      this.corporationList = tsBcncList;
    },
    onAddCorporation() {
      this.isCorporationRegistrationPopupOpen = true;
    },
    async registPopupClose(isRegistSuccess) {
      isRegistSuccess && await this.onSearchCprName();
      this.$nextTick(() => {
        this.$refs.corporationRegistrationPopup.hide();
        this.isCorporationRegistrationPopupOpen = false;
      });
    },
    onRecordClick(args, isFromEnterKey = false) {
      const params = isFromEnterKey ? args : args.rowData;
      this.$emit("confirmed", params);
    },
    onKeyPressed(args) {
      if (args.key === "Enter") {
        const [selectedRecord] = this.$refs.grid.getSelectedRecords();
        this.onRecordClick(selectedRecord, true);
      }
    },
    onOpen() {
      this.$nextTick(() => {
        if(this.searchCprName) {
          this.$refs.searchCprName.blur();
          this.$refs.grid.selectRow(0);
        }
      });
    },
  },
};
</script>